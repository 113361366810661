import React, { useEffect } from 'react';
import useIdentityPayKYC from 'react-identity-kyc';
// import PremblyKYC from 'react-identity-kyc';

const FaceLivenessDetection = () => {
  const config = {
    first_name: 'test',
    last_name: 'test',
    email: 'kayode@myidentitypass.com',
    merchant_key: 'sandbox_pk_iE24bwdc4KlpPfyxSn90ZyY7S79fpv5xm3jGyHE',
    user_ref: '8888',
    is_test: false,
    config_id: 'd5d4c62c-b0c3-4062-9774-20c8193384b1',
    callback: (response) => console.log(response),
  };

  const verifyWithIdentity = useIdentityPayKYC(config);

  useEffect(() => {
    verifyWithIdentity();
  }, []);

  return (
    <div className="row text-center">
      <div>
        <h3>Liveness check</h3>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={verifyWithIdentity}
      >
        Proceed
      </button>
    </div>
  );
  /* const handleKYCSuccess = (data) => {
    console.log('Prembly KYC Success:', data);
    // Send data.verificationId to your backend for verification
  };

  const handleKYCError = (error) => {
    console.error('Prembly KYC Error:', error);
    // Handle the error
  };

  const handleKYCCancel = () => {
    console.log('Prembly KYC Cancelled');
    // Handle cancellation
  };

  const kycConfig = {
    steps: ['face'], // Only perform liveness check
    faceConfig: {
      // Optional face configuration
    },
    customization: {
      // Optional UI customization
    },
  };

  return (
    <div>
      <h1>Liveness Check</h1>
      <PremblyKYC
        publicKey="sandbox_pk_iE24bwdc4KlpPfyxSn90ZyY7S79fpv5xm3jGyHE"
        config={kycConfig}
        onSuccess={handleKYCSuccess}
        onError={handleKYCError}
        onCancel={handleKYCCancel}
      />
    </div>
  ); */
};

export default FaceLivenessDetection;
