/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from 'react';
// import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import './index.css';
import Button from '../../../shared/Button';
import Back from '../../../../assets/backArrow.svg';
import {
  customBaseUrl,
  httpPostFormData,
  httpGet,
  // httpPost,
} from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import axios from 'axios';
import useUser from '../../../../pos/components/Hooks/useUser';
import { BillerContext } from '../../../../store/context/BillerContext';
import Swal from 'sweetalert2';
import envConfig from '../../../../utils/envConfig';
import { hideLoader, showLoader } from '../../../../utils/loader';

const CreateDispute = (props) => {
    // const { categData } =useContext(BillerContext);  
  const userObj = useUser() || {};
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const merchantId = userObj?.posUserProfile?.merchantId ?? "";
  const [currentPage, setCurrentPage] = useState(1);
  const { showModal, hideModal, id, setRefresh, refresh, terminals, products} = props;
  const [postData, setPostData] = useState({ type: 'ATM' });
  const [loading, setLoading] = useState(false);
  // const [DisputeCategory, setDisputeCategory] = useState('');
  const [disputeTypes] = useState([{label: 'WAYAPOS', value: 'POS'}, {label: 'WAYA ATM CARD', value:'ATM'}, {label:'BILLS PAYMENT', value:'BILLPAYMENT'}]);
  const { userDetails } = useContext(ProfileContext);
  const [isLoading, setIsloading] = useState(false);
  const [billers, setBillers] = useState([]);
  const [raisedBy, setRaisedBy] = useState('CUSTOMER');
  const [actNo, setActNo] = useState("");
  const [posCategory, setPOSCategory] = useState('TRANSFER');
  const [session_id, setSession_id] = useState('');
  const { grantType, clientId, secretId, disputeURL } = envConfig;

  const categData = [
    'airtime',
    'databundle',
    'cabletv',
    'electricity',
    'epin',
    'betting',
  ];


  useEffect(() => {

    (async () => {
      // const url =
      //   'https://services.staging.wayabank.ng/complaint-service/ticket-category';
      // const res = await httpGet(url);
      const res = await httpGet(
        `${customBaseUrl.wayapayDispute}/ticket-category`
      );
      console.log(res);
      // setDisputeTypes([...(res?.data?.ticketCategoryResponses ?? '')]);
    })();
  }, []);

  // onchange
  // const onChange = (e) => {
  //   e.preventDefault();
  //   let files;
  //   if (e.dataTransfer) {
  //     files = e.dataTransfer.files;
  //   } else if (e.target) {
  //     files = e.target.files;
  //   }
  //   setPostData({ ...postData, attachment: files[0] });
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     // setPreview(reader.result);
  //   };
  //   reader.readAsDataURL(files[0]);
  // };

  const validateInput = (value) => {
    const regex = /^WAYA\d{11}$/;
    return regex.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (postData.transaction_id && !validateInput(postData.transaction_id) )
      return Swal.fire(
        'Oops!',
        'Transaction Id must be WAYA followed by 11 digits.'
      );
    // console.log('post Data', postData);
    // const authBody ={
    //   grant_type:grantType,
    //   client_id: clientId,
    //   client_secret: clientId
    // }
    const authData = new FormData();
      authData.append('grant_type', grantType);
      authData.append('client_id', clientId);
      authData.append('client_secret',secretId)
    const authRes = await axios.post(`${disputeURL}/api/oauth2/token`,
      authData,
      {
        headers: {
          Authorization: '',
        }
      }
    );
    setIsloading(true);
    // const formData = new FormData();
    // formData.append('categoryId', postData?.categoryId);
    // formData.append('disputeDescription', postData?.disputeDescription);
    // formData.append('disputeSubject', postData?.disputeSubject);
    // formData.append('disputeType', postData?.disputeType);
    // formData.append('transactionAmount', postData?.transactionAmount);
    // formData.append('file', postData?.file);
    // formData.append('transactionId', postData?.transactionId);
    // formData.append('userId', userDetails?.userId);

    const posData = {
      type: postData.type,
      agent_id: JSON.stringify(userDetails.userId),
      merchant_id: merchantId,
      phone: userDetails.phoneNumber,
      amount: postData.amount,
      transaction_id: postData.transaction_id,
      trans_date: postData.trans_date,
      user_id: JSON.stringify(userDetails.userId),
      session_id,
      raised_by: raisedBy,
      customer_category: "BUSINESS",
      business_name: userObj?.user?.otherDetails?.organisationName ?? '',
      details: {
        rrn: postData.rrn,
        stan: postData.stan,
        terminal_id: postData.terminal_id,
        customer_id: JSON.stringify(userDetails.userId),
        pos_category: posCategory,
        card_type: postData.cardProduct,
        card_name: postData.cardName,
        pan: postData.pan,
        exp_date: postData.exp_date,
        reference_id: postData.referenceId,
        account_number: actNo,
        bill_product: null,
        bills_id: null,
        bill_category: postData.billCategory,
        biller_name: postData.biller_name,
      }
    }
    const atmData = {
      type: postData.type,
      agent_id: JSON.stringify(userDetails.userId),
      user_id: JSON.stringify(userDetails.userId),
      session_id,
      raised_by: raisedBy,
      customer_category: "BUSINESS",
      merchant_id: merchantId,
      phone: userDetails.phoneNumber,
      amount: postData.amount,
      transaction_id: postData.transaction_id,
      trans_date: postData.trans_date,
      business_name: userObj?.user?.otherDetails?.organisationName ?? '',
      details: {
          rrn: postData.rrn,
          stan: postData.stan,
          pan: postData.pan,
          customer_id: JSON.stringify(userDetails.userId),
          account_number: actNo
      }
    }
    const billData = {
      type: postData.type,
      agent_id: JSON.stringify(userDetails.userId),
      merchant_id: merchantId,
      phone: userDetails.phoneNumber,
      amount: postData.amount,
      transaction_id: postData.transaction_id,
      trans_date: postData.trans_date,
      user_id: JSON.stringify(userDetails.userId),
      session_id,
      raised_by: raisedBy,
      customer_category: "BUSINESS",
      business_name: userObj?.user?.otherDetails?.organisationName ?? '',
      details: {
          rrn: postData.rrn,
          stan: postData.stan,
          bill_category: postData.billCategory,
          biller_name: postData.biller_name,
          customer_id: JSON.stringify(userDetails.userId),
      }
    }
    showLoader();
    try{
    // const url = `${customBaseUrl.wayapayDispute}/dispute`;
    const res = await axios.post(`${disputeURL}/api/disputes`,
      postData?.type === 'POS' ? posData : postData?.type === 'ATM' ? atmData : billData,
      {
        headers: {
          Authorization: `${authRes.data.token_type} ${authRes.data.access_token}`,
          secret_id: secretId,
          client_id: clientId
        }
      }
    );
    setIsloading(false);
    if (res.status === 201){
      Swal.fire('Done', 'Dispute raised successfully')
      setRefresh(!refresh);
      hideModal(false);
    } else if(res.status === 40) {
      Swal.fire('Oops!', JSON.stringify(res.data?.detail));
    } else {
      Swal.fire('Oops!', '');

    }
    console.log({ res: res.data})
  }catch(e){
    Swal.fire('Oops!', JSON.stringify(e.response?.data?.detail));
    hideLoader();
    console.log({ rese: e.response?.data?.detail})

  } finally{
    hideLoader();
    // Swal.fire('Oops!', JSON.stringify(res.data?.detail));
  }
    // console.log(res.status, res);
    // if (res.status) {
    //   setRefresh(!refresh);
    //   swal('Success', res.message).then(() => {
    //     setLoading(false);
    //     hideModal(false);
    //   });
    // }
    // console.log('Dispute ticket', res);
  };
  /* const handleSocialSubmit = async () => {
    /* const dispute = {
      disputeTypeId: disputeTypes.filter(
        (el) => el.disputeTypeName === category
      )[0].disputetype_id,
      disputeCategoryId: 1,
      subjectOfDispute: postData.subject,
      disputeDescription: postData.description,
      categories: {
        categoryId: 0,
        disputeCategories: DisputeCategory,
      },
      disputeTypes: {
        disputeTypeName: category,
        disputetype_id: disputeTypes.filter(
          (el) => el.disputeTypeName === category
        )[0].disputetype_id,
      },
    };
  */ /*
    const newForm = new FormData();

    newForm.append(
      'dispute'
      // new Blob([JSON.stringify(dispute)], { type: 'application/json' })
      // JSON.stringify(dispute)
      // 'dispute.json'
    );

    postData.attachment &&
      newForm.append('attachment', postData.attachment, 'cacDocument.jpeg');
    // console.log('disputeData', dispute);
    const res = await httpPostFormData(
      '/api/v1/dispute',
      newForm,
      customBaseUrl.disputeUrl
    );
    // console.log('res', res);
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setLoading(false);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  */

  useEffect (() => {
    setSession_id(JSON.stringify(new Date().getTime()));
  }, [])
  useEffect(() => {
    const getBiller = async () =>{
      if(postData.billCategory) {
        const res = await httpGet(
          `${customBaseUrl.billPaymentUrl2}/api/bills/test/initiate?serviceType=${postData.billCategory}`
        );
        if (res?.status) {
          console.log('billerCategory', res);
          setBillers(res.data);
        }
      }
    }

    getBiller();
  }, [postData.billCategory])

  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        hideModal(false);
        setCurrentPage(1);
      }}
      id={id}
      centered
    >
      <div
        className="text-end w-100"
        onClick={() => {
          hideModal(false);
          setCurrentPage(1);
        }}
      >
        <FaTimes />
      </div>
      <ModalBody className="createDispute">
      <form onSubmit={handleSubmit}>
        <div className="dispute-header">
          {currentPage > 1 && (
            <img
              src={Back}
              alt=""
              className="ms-0"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
          )}
          <div className="mx-auto">
            {currentPage === 1 ? 'Create New Dispute' : 'Dispute Log'}
          </div>
        </div>
        <div className="pg-1-1">
            {/* <div className="title">
              SESSION ID <label style={{fontSize: 14}}>{session_id}</label>
            </div> */}
            {/* <div className="pg-1-input">
              <input
                type="text"
                required
                min={0}
                disabled
                value={session_id}
              />
            </div> */}
          </div>
        <div className="pg-1-1">
          <div className="title">
            Dispute Type <label style={{ color: 'red' }}>*</label>
          </div>
          <div className="pg-1-input">
          <select
                name=""
                id=""
                required
                value={JSON.stringify(
                  disputeTypes.filter((e) => e.value === postData.type)[0]
                )}
                onChange={(e) => {
                  const { value } = JSON.parse(e.target.value);
                  setPOSCategory('TRANSFER');
                  setPostData({
                    ...postData,
                    type: value,
                  });
                }}
              >
                <option>Select Dispute Type</option>
                {disputeTypes.map((el) => (
                  <option key={el} value={JSON.stringify(el)}>
                    {el.label}
                  </option>
                ))}
              </select>
          </div>
          {postData.type === 'POS' && <div className="pg-1-input">
          <div className="title">
            POS Category <label style={{ color: 'red' }}>*</label>
          </div>
            <select
                onChange={(e) =>  setPOSCategory(e.target.value)}
              
                value={posCategory}
                required
              >
                <option>...</option>
                  <option value="TRANSFER">
                    TRANSFER
                  </option>
                  <option value="BILLS_PAYMENT">
                    BILLS PAYMENT
                  </option>
                  <option value="CASH_OUT">
                    CASH OUT
                  </option>
              </select>
            </div>}
        </div>
        <div className="pg-1-1" hidden>
            <div className="title">
              MERCHANT ID: <label style={{ color: 'red' }}>*</label>
            </div>
            <div className="pg-1-input">
              <input
                type="text"
                required
                min={0}
                disabled
                value={merchantId}
              />
            </div>
          </div>
          <div className="pg-1-1" hidden>
            <div className="title">
              Business Name<label style={{ color: 'red' }}>*</label>
            </div>
            <div className="pg-1-input">
              <input
                type="text"
                min={0}
                disabled
                required
                value={userObj?.user?.otherDetails?.organisationName ?? ''}
              />
            </div>
          </div>
          <div className="title">
            Raised By <label style={{ color: 'red' }}>*</label>
          </div>
          <div className="pg-1-input">

          <select
              onChange={(e) =>  setRaisedBy(e.target.value)}
            
              value={raisedBy}
              required
            >
              <option>Raised by</option>
                <option value="MERCHANT">
                  MERCHANT
                </option>
                <option value="CUSTOMER">
                  CUSTOMER
                </option>
            </select>
          </div>
          <div className="pg-1-1" hidden>
            <div className="title">
              MERCHANT PHONE
              <label style={{ color: 'red' }}>*</label>
            </div>
            <div className="pg-1-input">
              <input
                type="text"
                min={0}
                disabled
                value={userDetails.phoneNumber}
              />
            </div>
          </div>
        <div className="pg-1-1">
            <div className="title">Dispute Amount<label style={{ color: 'red' }}>*</label></div>
            <div className="pg-1-input">
              <CurrencyInput
                id="amount"
                name="amount"
                required
                placeholder="Dispute Amount"
                defaultValue={0.0}
                decimalsLimit={2}
                onValueChange={(value, name, values) =>
                  setPostData({ ...postData, amount: values?.value })
                }
              />
              {/* <input
                type="number"
                min={0}
                required
                value={postData.amount}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    amount: e.target.value,
                  })
                }
              /> */}
            </div>
          </div>
        {/* <div className="pg-1-1">
          <div className="title">Dispute Subject</div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.disputeSubject}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  disputeSubject: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">Dispute Description</div>
          <div className="pg-1-input">
            <textarea
              required
              value={postData.disputeDescription}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  disputeDescription: e.target.value,
                })
              }
              cols="40"
              rows="5"
            />
          </div>
        </div> */}
        <div className="pg-1-1">
          <div className="title">
            RRN (Reference Retrieval Number)
            {postData?.type === 'ATM' || posCategory === 'CASH_OUT' ? (
              <label style={{ color: 'red' }}>*</label>
            ): ''}
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.rrn}
            required={postData?.type === 'ATM' || posCategory === 'CASH_OUT'}
              minLength={12}
              maxLength={12}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  rrn: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">
            STAN (System Trace Audit Number)
            {postData.type === 'ATM' || posCategory === 'CASH_OUT' ? (
              <label style={{ color: 'red' }}>*</label>
            ) : ''}
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.stan}
              required={postData?.type === 'ATM' || (postData?.type === 'POS' && posCategory === 'CASH_OUT')}
              maxLength={6}
              minLength={6}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  stan: e.target.value,
                })
              }
            />
          </div>
        </div>
        {postData?.type === 'ATM' ?
          <div className="pg-1-1">
            <div className="title">PAN (Card Number)<label style={{ color: 'red' }}>*</label></div>
            <div className="pg-1-input">
              <input
                type="text"
                value={postData.pan}
                maxLength={16}
                minLength={16}
                required
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    pan: e.target.value,
                  })
                }
              />
            </div>
            <div className="title"> Account Number
            {postData?.type === 'ATM' || posCategory === 'TRANSFER' && (
              <label style={{ color: 'red' }}>*</label>
            )}            </div>
            <div className="pg-1-input">
              <input
                type="text"
                required={postData?.type === 'ATM' || posCategory === 'TRANSFER'}
                maxLength={10}
                minLength={10}
                value={actNo}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  setActNo(numericValue)
                }}
              />
            </div>
          </div> : ''}
        <div className="pg-1-1">
          <div classsName="title">Transaction ID
            {postData?.type === 'POS' ? '' : <label style={{ color: 'red' }}>*</label>}
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.transaction_id}
              required={!postData?.type === 'POS'}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  transaction_id: e.target.value,
                })
              }
            />
          </div>
        </div>
        {postData?.type === 'POS' ?
        <div className="pg-1-1">
          <div className="title">Terminal Name <label style={{ color: 'red' }}>*</label></div>
          <div className="pg-1-input">

          <select
              onChange={(e) =>  setPostData({
                ...postData,
                terminal_id: e.target.value,
              })
            }
              value={postData.terminal_id}
            >
              <option>Select terminal</option>
              {terminals?.map((e) =>
                <option value={e?.terminalId} key={e?.id}>
                  {e?.actualTerminalName} - {e?.terminalId}
                </option>
              )}
            </select>
          </div>
          {(posCategory === 'BILLS_PAYMENT1') && (
          <>
          <div className="pg-1-1">
            <div className="pg-1-1">
              <div className="title">Bill Category <label style={{ color: 'red' }}>*</label></div>
              <div className="pg-1-input">
                <select
                  name=""
                  id=""
                  required
                  onChange={(e) => {
                    const { value } = e.target;
                    setPostData({...postData, billCategory: value})
                  }}
                >
                  <option value="">Select Bill Category</option>
                  {categData?.map(e => 
                  <option value={e}>{e}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          {/* <div className="page-1"> */}
          <div className="pg-1-1">
            <div className="title">Biller Name<label style={{ color: 'red' }}>*</label></div>
            <div className="pg-1-input">
              <select
                name=""
                required
                id=""
                value={postData.biller_name}
                onChange={(e) => setPostData({...postData, biller_name: e.target.value})}
              >
                <option value="">Select Biller</option>
                {billers?.map(e => 
                <option value={e.name}>{e?.name}</option>
                )}
              </select>
            </div>
          </div>
        {/* </div> */}
        <div className="pg-1-1">
          <div className="title">
            Bill Id
            {/* <label style={{ color: 'red' }}>*</label> */}
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.bills_id}
              // required
              // minLength={6}
              // maxLength={6}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  bills_id: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">
            REF Id
            {/* <label style={{ color: 'red' }}>*</label> */}
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.reference_id}
              // required
              // minLength={6}
              // maxLength={6}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  reference_id: e.target.value,
                })
              }
            />
          </div>
        </div>
      </>
          
          )}
          {posCategory === 'CASH_OUT' && (
            <>
            <div className="title">Card Type<label style={{ color: 'red' }}>*</label></div>
          <div className="pg-1-input">

          <select
              aria-label="select state"
              className="form-select border"
              required
              id="stateSelect"
              onChange={(e) => {
                const { productName } = JSON.parse(e.target.value);
                setPostData({ ...postData, cardProduct: productName });
              }}
            >
              <option value="">select card type</option>
              {products?.map((e) => (
                <option key={e?.productName} value={JSON.stringify(e)}>
                  {e?.productName}
                </option>
              ))}
            </select>
          </div>
          <div className="title">PAN (Card Number)<label style={{ color: 'red' }}>*</label></div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.pan}
              maxLength={16}
              minLength={16}
              required
              onChange={(e) =>
                setPostData({
                  ...postData,
                  pan: e.target.value,
                })
              }
            />
          </div>
          <div className="pg-1-input">
            <div className="title">Card Expiry <label style={{ color: 'red' }}>*</label></div>
            <div className="pg-1-input">
            <input
              type="text"
              value={postData.exp_date}
              maxLength={5}
              minLength={5}
              required
              placeholder='05/26'
              onChange={(e) =>
                setPostData({
                  ...postData,
                  exp_date: e.target.value,
                })
              }
            />
            </div>            
          </div>
          <div className="title">Card Name<label style={{ color: 'red' }}>*</label></div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.cardName}
              // maxLength={20}
              // minLength={20}
              required
              onChange={(e) =>
                setPostData({
                  ...postData,
                  cardName: e.target.value,
                })
              }
            />
          </div>
          </>)}
          <div className="title">Reference Id
            {/* <label style={{ color: 'red' }}>*</label> */}
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.referenceId}
              // maxLength={20}
              // minLength={20}
              // required
              onChange={(e) =>
                setPostData({
                  ...postData,
                  referenceId: e.target.value,
                })
              }
            />
          </div>
          <div className="title"> Account Number
            {postData?.type === 'ATM' || posCategory === 'TRANSFER'  &&  (
              <label style={{ color: 'red' }}>*</label>
            )}          </div>
            <div className="pg-1-input">
              <input
                type="text"
                required={postData?.type === 'ATM' || posCategory === 'TRANSFER'}
                maxLength={10}
                minLength={10}
                value={actNo}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  setActNo(numericValue)
                }}
              />
            </div>
        </div> : ''}
        <div className="pg-1-1">
              <div className="pg-1-1">
                <div className="title">Transaction date <label style={{ color: 'red' }}>*</label></div>
                <div className="pg-1-input">
                  <input
                    type="date"
                    required
                    max={today}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        trans_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            {postData?.type === 'BILLPAYMENT'&& (
          <>
          <div className="pg-1-1">
            <div className="pg-1-1">
              <div className="title">Bill Category <label style={{ color: 'red' }}>*</label></div>
              <div className="pg-1-input">
                <select
                  name=""
                  id=""
                  required
                  onChange={(e) => {
                    const  { value } = e.target;
                    setPostData({...postData, billCategory: value, biller_name: ''})
                  }}
                >
                  <option value="">Select Bill Category</option>
                  {categData?.map(e => 
                  <option value={e}>{e}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="pg-1-1">
          <div className="pg-1-1">
            <div className="title">Biller Name<label style={{ color: 'red' }}>*</label></div>
            <div className="pg-1-input">
              <select
                name=""
                required
                id=""
                value={postData.biller_name}
                onChange={(e) => setPostData({...postData, biller_name: e.target.value})}
              >
                <option value="">Select Biller</option>
                {billers?.map(e => 
                <option value={e.name}>{e?.name}</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">
            Bill Id
            <label style={{ color: 'red' }}>*</label>
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.bills_id}
              required
              // minLength={6}
              // maxLength={6}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  bills_id: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">
            REF Id
            {/* <label style={{ color: 'red' }}>*</label> */}
          </div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.reference_id}
              // required
              // minLength={6}
              // maxLength={6}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  reference_id: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="title"> Account Number
            {/* {postData?.type === 'BILLPAYMENT' &&  (
              <label style={{ color: 'red' }}>*</label>
            )}           */}
            </div>
            <div className="pg-1-input">
              <input
                type="text"
                // required={postData?.type === 'BILLPAYMENT'}
                maxLength={10}
                minLength={10}
                value={actNo}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  setActNo(numericValue)
                }}
              />
            </div>
      </>
          
          )}
        {/* <div className="pg-1-1">
          <div className="title">Transaction ID</div>
          <div className="pg-1-input">
            <input
              type="text"
              value={postData.transactionId}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  transactionId: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="title">Attach File</div>
          <div className="pg-1-input">
            <input
              type="File"
              onChange={(e) =>
                setPostData({
                  ...postData,
                  file: e.target.files[0],
                })
              }
            />
          </div>
        </div> */}
        {/* category && currentPage === 1 && (
          <div className="page-1">
            <div className="pg-1-1">
              <div className="title">Transaction Amount</div>
              <div className="pg-1-input">
                <input
                  type="number"
                  value={postData.transactionAmount}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      transactionAmount: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="pg-1-1">
              <div className="title">Narration of Dispute</div>
              <div className="pg-1-input">
                <input
                  type="text"
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      narrationOfDispute: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="pg-1-1">
              <div className="title">Extra Details</div>
              <div className="pg-1-input">
                <input
                  type="text"
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      extraDetails: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="page-1">
              <div className="pg-1-input">
                <div className="title">Enter transaction date</div>
                <div className="pg-1-input">
                  <input
                    type="date"
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        transactionDate: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="page-1">
              <div className="pg-1-input">
                <div className="title">Transaction ID</div>
                <div className="pg-1-input">
                  <input
                    type="text"
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        transactionId: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {category === 'payment' && currentPage === 2 && (
          
        )} */}

        {/* category === 'socials' && (
          <div className="page-1">
            <div className="pg-1-input">
              <div className="title">Dispute Category</div>
              <div className="pg-1-input">
                <select
                  name=""
                  id=""
                  onChange={(e) => setDisputeCategory(e.target.value)}
                >
                  <option value="">Select Dispute Category</option>
                  <option value="Donations">Donations</option>
                  <option value="Events">Events</option>
                  <option value="Polling">Polling</option>
                  <option value="Post">Post</option>
                  <option value="Comment">Comment</option>
                  <option value="Moment">Moment</option>
                  <option value="Page">Page</option>
                  <option value="Groups">Groups</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className="pg-1-1">
              <div className="title">Dispute Subject</div>
              <div className="pg-1-input">
                <input
                  type="text"
                  value={postData.subject}
                  onChange={(e) =>
                    setPostData({ ...postData, subject: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="pg-1-input">
              <div className="title">Dispute Description</div>
              <textarea
                name=""
                id=""
                style={{
                  height: '120.76px',
                }}
                value={postData.description}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        {category === 'auth' && (
          <div className="page-1">
            <div className="pg-1-input">
              <div className="title">Dispute Category</div>
              <div className="pg-1-input">
                <select name="" id="">
                  <option value="">Select Dispute Category</option>
                  <option value="Email Notifications">
                    Email Notifications
                  </option>
                  <option value="SMS Notifications">SMS Notifications</option>
                  <option value="In App Notifications">
                    In App Notifications
                  </option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>

            <div className="pg-1-input">
              <div className="title">Dispute Description</div>
              <textarea
                name=""
                id=""
                style={{
                  height: '120.76px',
                }}
              />
            </div>
          </div>
        )}
        {/* {category !== '' && (
          <div className="pg-1-1">
            <div className="title">Attach Files</div>
            <FileInput onChange={onChange} />
          </div>
        )} */}
        <div className="pg-1-1">
            <div className="title">Customer Email (optional)</div>
            <div className="pg-1-input">
              <input
                type="email"
                min={0}
                value={postData.customerEmail}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    customerEmail: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="pg-1-1">
            <div className="title">Customer Phone Number(optional)</div>
            <div className="pg-1-input">
             <input
                type='text'
                minLength={11}
                maxLength={11}
                value={postData.customerPhone}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  setPostData({ ...postData, customerPhone: numericValue })
                }}
              />
              {/* <input
                type="text"
                min={0}
                value={postData.customerPhone}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    customerPhone: e.target.value,
                  })
                }
              /> */}
            </div>
          </div>
        <div className="btn-div">
          <button
            className="btn btn-primary"
            type='submit'
            disabled={loading || false}
            loading={isLoading}
            content="Submit"
            // onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
// const FileInput = ({ onChange }) => {
//   const inputFileRef = useRef();
//   return (
//     <div className="pg-1-input" style={{ position: 'relative' }}>
//       <input
//         disabled
//         type="text"
//         // value={postData.phoneNumber}
//         // onChange={(e) =>
//         //   setPostData({ ...postData, phoneNumber: e.target.value })
//         // }
//       />
//       <div
//         style={{
//           paddingRight: 10,
//           paddingLeft: 10,
//           backgroundColor: '#ECECEC',
//           width: '20%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'absolute',
//           right: 0,
//           paddingTop: 11,
//           marginRight: 1,
//           borderRadius: '0px 3px 3px 0px',
//         }}
//       >
//         <input
//           onChange={(e) => onChange(e)}
//           ref={inputFileRef}
//           type="file"
//           style={{ display: 'none' }}
//         />
//         <p
//           style={{
//             color: '#666666',
//             fontSize: 13,
//             height: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//           onClick={() => {
//             inputFileRef.current.click();
//           }}
//         >
//           Browse
//         </p>
//       </div>
//     </div>
//   );
// };
export default CreateDispute;
