import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
//  import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import PinDetails from '../Confirm/PinDetails';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import Button from '../../Button';
// import { BillerContext } from '../../../../store/context/BillerContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { hideLoader, showLoader } from '../../../../utils/loader';

const Cable = (props) => {
  const { showModal, hideModal, id, walletdata, setWalletData } = props;
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [pin, setPin] = useState('');
  const [network, setNetwork] = useState('');
  const [months, setMonths] = useState('');
  const [balance, setBalance] = useState();
  // const [postData, setPostData] = useState({});
  // const [inputFields, setInputFields] = useState([]);
  const [smartCard, setSmartCard] = useState('');
  const [biller] = useState([]);
  // const [billerCable, setBillerCable] = useState([]);
  const [accountCable, setAccountCable] = useState('');
  // const [bilAirtime] = useState([]);
  const [cableAmount, setCableAmont] = useState(null);
  const [dataBundle, setDataBundle] = useState([]);
  const [charges, setCharges] = useState(0);
  const [activeAggregator, setAggregator] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  // const { cabletv } = useContext(BillerContext);
  const [cabletv, setCableTv] = useState([]);
  const { setReload, walletList, reload } = useContext(WalletContext);
  const { profile } = useContext(ProfileContext);
  const [product, setProduct] = useState([]);
  const [setBundle] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [cableBundle, setCableBundle] = useState([]);
  // const [isValidationRequired, setIsValidationRequired] = useState(false);
  // const [customerName, setCustomerName] = useState('');

  /* const getAmount = (bundles) => {
    if (bundles.length) {
      console.log('amount', bundles?.amount);
      setCableAmont(+bundles?.amount);
    }
  }; */

  /*  const getbillerCategory = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category/${biller}`
    );
    if (res?.status) {
      console.log('billerCategory', res);
      setBillerCable(res.data);
    }
    console.log('billerNameData', billerCable);
  };  */

  const getProduct = async (bid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/bills/test/cable-tv?provider=${bid}`
    );
    if (res?.status) {
      hideLoader();
      setProduct(res?.data?.data);
    } else {
      hideLoader();
    }
  };
  const VerifyCustomer = async (sCard) => {
    // console.log({ dataBundle });
    // const data = {
    //   account: sCard,
    //   serviceProviderCategoryId:
    //     dataBundle?.serviceProviderProduct?.serviceProviderBiller
    //       ?.serviceProviderCategory?.id,
    //   serviceProviderId:
    //     dataBundle?.serviceProviderProduct?.serviceProviderBiller
    //       ?.serviceProviderId,
    //   type: cableBundle?.type ?? dataBundle?.serviceProviderProduct?.type,
    // };
    const data = {
      accountNumber: sCard,
      provider: activeAggregator,
    };
    showLoader();
    const res = await httpPost(
      `/api/bills/test/customer/validate`,
      data,
      customBaseUrl.billPaymentUrl2
    );
    if (res?.status) {
      hideLoader();
      setCustomerDetails(res?.data?.data?.user);
    } else {
      hideLoader();
    }
  };
  const getBundle = async (pid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBundleByProduct?serviceProviderProductId=${pid}`
    );
    if (res?.status) {
      hideLoader();
      setBundle(res?.data);
    } else {
      hideLoader();
    }
  };
  // const getAgregator = async () => {
  //   const res = await httpGet(
  //     `${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`
  //   );
  //   const aggregator = res?.data;
  //   const quick =
  //     aggregator?.filter((e) => e.aggregator === 'QUICKTELLER') || {};
  //   const baxi = aggregator?.filter((e) => e.aggregator === 'BAXI') || {};
  //   const itex = aggregator?.filter((e) => e.aggregator === 'ITEX') || {};
  //   if (quick[0]?.active) {
  //     setAggregator('QUICKTELLER');
  //   } else if (baxi[0]?.active) {
  //     setAggregator('BAXI');
  //   } else if (itex[0]?.active) {
  //     setAggregator('ITEX');
  //   }
  // };

  const getCharges = async () => {
    const res = await httpGet(
      `${customBaseUrl.temporalservice}/api/v1/wallet/transaction/get-user-transaction-fee/${walletdata?.walletAccountNo}/${cableAmount}/${activeAggregator}`
    );
    // console.log('res', res);
    if (res?.status !== 404) {
      setCharges(res);
    }
    // console.log('Charges', charges);
  };

  const handleInitialize = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/bills/test/initiate?serviceType=cabletv`
    );
    console.log('res', res);
    if (res?.status === true) {
      setCableTv(res.data);
    }
  };

  useEffect(() => {
    handleInitialize();
  }, []);
  useEffect(() => {
    if (activeAggregator) getCharges();
  }, [walletdata?.walletAccountNo, cableAmount]);

  // useEffect(() => {
  //   getAgregator();
  // }, []);

  const handleSubmit = async () => {
    // console.log('bundle', dataBundle);
    setLoading(true);
    if (validator.isEmpty(profile?.phoneNumber)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }

    /*  const formattedPhone = `${parsePhoneNumber(phone).countryCallingCode}${
      parsePhoneNumber(phone).nationalNumber
    }`;

    if (/\D/.test(formattedPhone)) {
      swal(
        'Oops!',
        'Phone number accepts only numeric characters (Allowed input:0-9)',
        'error'
      );
      setLoading(false);
      return;
    }

    if (formattedPhone.length > 13) {
      swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      setLoading(false);
      return;
    } */

    if (dataBundle.length < 0) {
      swal('Oops', 'Choose a Product', 'error');
      setLoading(false);
      return;
    }
    showLoader();
    // const data = {
    //   amount: cableAmount,
    //   monthPaidFor: dataBundle?.monthsPaidFor,
    //   phone: profile?.phoneNumber,
    //   productCode:
    //     dataBundle?.serviceProviderProduct?.productCode ||
    //     dataBundle?.productCode,
    //   smartCardNumber: smartCard,
    //   type:
    //     cableBundle?.type ??
    //     dataBundle?.serviceProviderProduct?.type ??
    //     dataBundle?.serviceProviderBiller?.type,
    //   //  defaultWallet.id,
    // };
    const data = {
      amount: cableAmount,
      monthPaidFor: dataBundle?.monthsPaidFor,
      phone: profile?.phoneNumber,
      productCode: cableBundle.code,
      provider: activeAggregator.toUpperCase(),
      smartCardNumber: +smartCard,
      sourceAccount: {
        account: accountCable,
        pin,
      },
      userEmail: profile.email,
    };
    const res = await httpPost(
      `/api/bills/test/cable-tv/pay`,
      data,
      customBaseUrl.billPaymentUrl2
    );
    if (res.status) {
      setLoading(false);
      swal('Done', res?.data?.message, 'success');
      hideModal(false);
      setReload(!reload);
      hideLoader();
      setPin('');
    } else {
      setPin('');
      setLoading(false);
      hideLoader();
      swal('Oops', res?.message, 'error');
    }
    hideLoader();
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        Cable Subscription
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              // value={network}
              onChange={(e) => {
                // getFieldParams(e.target.value);
                const {
                  name,
                  // id: pId,
                  // serviceProviderCategory: {
                  //   serviceProvider: { name },
                  // },
                } = JSON.parse(e.target.value);
                setProduct([]);
                // setBundle([]);
                setCableAmont('');
                setCustomerDetails([]);
                setAggregator(name);
                // if (hasProduct) {
                getProduct(name);
                // }
                setNetwork(JSON.parse(e.target.value));
              }}
            >
              <option value="" selected data-default hidden>
                Service Provider
              </option>
              {cabletv.length
                ? cabletv.map((item) => (
                    <option value={JSON.stringify(item)} key={item.id}>
                      {item.name}
                    </option>
                  ))
                : ''}
            </select>
          </div>

          {product?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  //  getAmount(e.target.value);
                  // setCableAmont('');
                  if (JSON.parse(e.target.value)?.hasBundle) {
                    getBundle(JSON.parse(e.target.value)?.id);
                  }
                  // else {
                  //   setCableAmont(JSON.parse(e.target.value));
                  // }
                  // console.log({ KKK: JSON.parse(e.target.value) });
                  setCableBundle(JSON.parse(e.target.value));
                  //  setAirtimeProd(e.target.value);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Product
                </option>
                {product
                  ? product.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {cableBundle?.availablePricingOptions?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  setDataBundle(JSON.parse(e.target.value));
                  setCableAmont(JSON.parse(e.target.value)?.price);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Bundle
                </option>
                {cableBundle
                  ? cableBundle?.availablePricingOptions?.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        Months-{item?.monthsPaidFor} - N
                        {moneyFomatter(item?.price)} -{}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {cableAmount ? (
            <div className="inputbox-with-one-input">
              <input
                type="text"
                disabled
                value={cableAmount ? `N${moneyFomatter(cableAmount)}` : ''}
                placeholder="Amount"
              />
            </div>
          ) : (
            ''
          )}
          {network && cableAmount && (
            <>
              <div className="inputbox-with-one-input">
                <input
                  type="number"
                  required
                  value={smartCard}
                  placeholder="Smart Card Number"
                  onChange={(e) => {
                    setSmartCard(e.target.value);
                    setCustomerDetails([]);
                    if (e.target.value.length > 9) {
                      VerifyCustomer(e.target.value);
                    }
                  }}
                />
              </div>
              {customerDetails?.name ? (
                <div className="inputbox-with-one-input">
                  <input
                    type="text"
                    required
                    disabled
                    value={customerDetails?.name}
                    placeholder="custmer name"
                  />
                </div>
              ) : (
                <div className="inputbox-with-one-input">
                  <input
                    type="text"
                    required
                    disabled
                    value=""
                    placeholder="customer name"
                  />
                </div>
              )}
            </>
          )}
          {network && biller === 'cabletv' ? (
            <div className="inputbox-with-one-input">
              <input
                type="number"
                value={months}
                placeholder="Number of Months"
                onChange={(e) => {
                  setMonths(e.target.value);
                }}
              />
            </div>
          ) : (
            ''
          )}

          {/* {network ? (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Phone Number"
                disabled
                value={profile?.phoneNumber}
              />
            </div>
          ) : (
            ''
          )} */}

          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={walletdata.walletAccountId}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setWalletData({
                  ...walletdata,
                  walletAccountNo: accountNo,
                });
                setBalance(amount);
                setAccountCable(accountNo);
              }}
            >
              <option value="Select" selected>
                Select Account
              </option>
              {walletList.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option
                          value={`${item.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {/* {`${item?.acct_crncy_code}-${item?.accountNo}`} */}
                          {`${item?.description} - ${item?.accountNo} (${
                            item.acct_crncy_code
                          } ${moneyFomatter(
                            Number(item?.clr_bal_amt).toFixed(2)
                          )})`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>
          <div>
            <span> Transaction Fees N{charges || 0}.00 </span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            if(!activeAggregator){
              swal('Oops!', 'Select a service provider', 'error');
              return;
            }
            if(!cableBundle){
              swal('Oops!', 'Select a product', 'error');
              return;
            }
            if(!smartCard){
              swal('Oops!', 'Enter Smart Card Number', 'error');
              return;
            }
            if(!accountCable){
              swal('Oops!', 'Select account to debit', 'error');
              return;
            }
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="Cable Subscription"
          otp={pin}
          setOtp={setPin}
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Cable.defaultProps = {
  id: '',
};

Cable.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Cable;
